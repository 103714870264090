import React, {useState} from 'react';
// import { Link } from 'gatsby';
import {MyContext} from '../context/AppContext';
import CashIcon from '../images/cash-icon.webp';
import CardIcon from '../images/card-icon.webp';
import AddWishlist from './Whishlist/AddWishlist';
import OutStockIcon from "../images/out-of-stock.png"
import '../styles/components/productCustomCard.css';

const ProductCardLiveCustom = ({product, theme, title, template}) => {
    const {addToCart, modalCart} = React.useContext(MyContext);
    const [liveProduct, setLiveProduct] = useState(product);
    const [isLoading, setIsLoading] = useState(true);
    // console.log(product)
    const fetchProductData = async () => {
        try {
            setIsLoading(true);  // Inicia la carga
            // Asumiendo que tienes un endpoint como /products/{id} para obtener detalles del producto
           const response = await fetch(`https://crm.dust2.gg/api/crm/products/${product.slug}`);
            const data = await response.json();

            setLiveProduct(data);
        } catch (error) {
            console.error("Error fetching product data:", error);
        } finally {
            setIsLoading(false);  // Finaliza la carga
        }
    };
    React.useEffect(() => {
        fetchProductData();
    }, []);
    const handleClick = (item) => {
        const productCart = {
            wordpress_id: item.wordpress_id,
            sku: item.sku,
            name: item.name,
            slug: item.slug,
            price: item.price,
            dimensions: item.dimensions,
            weight: item.weight,
            image: item.images[0]?.src,
            description: item.description,
            quantity: 1,
            tags: item.tags,
            stock_quantity: item.stock_quantity,
        }
        console.log(productCart)
        addToCart(productCart);
        if (template != "cart") {
            modalCart(true);
        }
    }
    const discountPrice = Number(liveProduct?.price) - (Number(liveProduct?.price) * 0.07).toFixed(0);
    let envioRapidoTag;
    if (product && Array.isArray(product.tags)) {
      envioRapidoTag = product.tags.find(item => item.slug === "envio-rapido");
    }

    let flashOffertsTag;
    if (product && Array.isArray(product.tags)) {
        flashOffertsTag = product.tags.find(item => item.slug === "oferta-flash");
    }

    let fastShippingTag;
    if (product && Array.isArray(product.tags)) {
        fastShippingTag = product.tags.find(item => item.slug === "verde");
    }

    let freeShippingTag;
    if (product && Array.isArray(product.tags)) {
        freeShippingTag = product.tags.find(item => item.slug === "envio-gratis-en-santiago");
    }
    // console.log(product);
    return (
        <div className={`productCustomCard`}>
            <div className='productCustomCard__image'>
                <a href={`/producto/${product.slug}`}><img src={product.product_card_image} alt="#" /></a>
            </div>
            <div className='productCustomCard__info'>
                <h2>{product.name}</h2>
                <div className="productCard__details--prices-price">
                    <p>Transf:</p>
                    <span>
                        {isLoading ? 
                            <div style={{width: "80px", height: "25px"}} className="skeleton-loader"></div> : 
                             `$${new Intl.NumberFormat('de-DE').format(Number(liveProduct.price).toFixed(0))}`
                           
                        }
                    </span>
                    {isLoading ? 
                        <div style={{width: "25px", height: "25px"}} className="skeleton-loader"></div> : 
                        <img src={CashIcon} alt="#" />
                    }
                    
                </div>
                <div className="productCard__details--prices-price" style={{display: "flex"}}>
                    <p>Tarjeta:</p>
                    <span>
                        {isLoading ? 
                            <div style={{width: "80px", height: "25px"}} className="skeleton-loader"></div> : 
                             `$${new Intl.NumberFormat('de-DE').format(Number(liveProduct.price / 0.93).toFixed(0))}`
                        }
                    </span>
                    {isLoading ? 
                        <div style={{width: "25px", height: "25px"}} className="skeleton-loader"></div> : 
                        <img src={CardIcon} alt="#" />
                    }
                </div>
                <div className='actions-test' style={{paddingTop: "5px", paddingBottom: "5px", width: "100%"}}>
                    {isLoading ? 
                        <div style={{width: "100%", height: "25px"}} className="skeleton-loader"></div> : 
                        (
                            <>
                            {product.stock_quantity <= 0 ? (
                                <>
                                    <div className="singleProduct__right--addToCart-addButton out-of-stock" style={{height: "2.5vw"}}>
                                        <button style={{ background: "#871111d1" }} onClick={() => { handleClick(product) }}>
                                            <span>Agotado</span> 
                                            <img src={OutStockIcon} alt="" />
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <button style={{height: "30px", background: "#860e86"}} onClick={() => handleClick(liveProduct)}>{title}</button>
                            )}
                            </>
                        )
                        
                    }
                </div>
            </div>
           
        </div>
    );
}

export default ProductCardLiveCustom;
